import { deepmerge } from '@fastify/deepmerge';

export const overridePSUrlParameters = (url = '', overrideParams = {}, removeParams = [], useDefaultRates = false) => {
    const psSearchParams = new URLSearchParams(url);
    if (useDefaultRates && !psSearchParams.has('defaultRate')) {
        psSearchParams.append('defaultRate', true);
    }
    Object.entries(overrideParams).forEach(([key, value]) => {
        psSearchParams.set(key, value);
    });
    removeParams.forEach((params) => {
        psSearchParams.delete(params);
    });
    return psSearchParams.toString();
};

export default function processPaymentServices(listing, features) {
    const { paymentServices = {}, ownerName } = listing;

    const {
        paymentDealerId,
        paymentPartnerId,
        forceDefaultRates,
        isNdsLeaseEnabled,
        leaseEnabledDRTypes,
        priceBkdwnEnabledDRTypes,
        isPriceBreakdownEnabled,
        enabledNonDTPaymentServices,
        enableFBAPaymentCalc,
    } = features;

    let { digitalRetailingType } = paymentServices;

    if (paymentServices.digitalRetailingType === 'accelerate') {
        // Treat non-migrated Accelerate listings as default
        if (!paymentServices.dealerSettings?.uiEnabled) {
            digitalRetailingType = 'default';
        }
    }

    const leaseEnabled = !!paymentServices.dealerSettings?.leaseEnabled
        && isNdsLeaseEnabled
        && !!leaseEnabledDRTypes[`enable_${digitalRetailingType}`];

    const priceBreakdownEnabled = isPriceBreakdownEnabled
        && priceBkdwnEnabledDRTypes[`enable_${digitalRetailingType}`] || false;

    const overridePaymentCalcParams = {
        enableLease: leaseEnabled,
    };

    const overrideIncentivesParams = {};

    const removeParams = [];

    if (!priceBreakdownEnabled) {
        overridePaymentCalcParams.disableIncentives = true;
        overridePaymentCalcParams.hasSpecialOffers = false;
    }

    if (enabledNonDTPaymentServices && paymentServices.digitalRetailingType === 'default' && ownerName !== 'Private Seller') {
        removeParams.push('defaultRate');
    }

    if (!!paymentDealerId && enableFBAPaymentCalc) {
        overridePaymentCalcParams.dealerId = paymentDealerId;
        overridePaymentCalcParams.defaultRate = false;
        overrideIncentivesParams.dealerId = paymentDealerId;
    }

    if (!!paymentPartnerId && enableFBAPaymentCalc) {
        overridePaymentCalcParams.partnerId = paymentPartnerId;
        overrideIncentivesParams.partnerId = paymentPartnerId;
    }

    const updatedPSUrl = overridePSUrlParameters(
        paymentServices.paymentCalculationUrl,
        overridePaymentCalcParams,
        removeParams,
        forceDefaultRates
    );

    const updatedIncentivesUrl = overridePSUrlParameters(
        paymentServices.incentivesUrl,
        overrideIncentivesParams,
        [],
        forceDefaultRates
    );

    // Update the paymentServices directly in the inventory item
    listing.paymentServices = deepmerge()(listing.paymentServices, {
        dealerSettings: { leaseEnabled, priceBreakdownEnabled },
        paymentCalculationUrl: updatedPSUrl,
        ...(paymentServices.incentivesUrl && { incentivesUrl: updatedIncentivesUrl }),
        digitalRetailingType,
    });
}
